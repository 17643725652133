// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attorneys-js": () => import("./../../../src/pages/attorneys.js" /* webpackChunkName: "component---src-pages-attorneys-js" */),
  "component---src-pages-brian-smith-js": () => import("./../../../src/pages/brian-smith.js" /* webpackChunkName: "component---src-pages-brian-smith-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-pages-sara-pfost-js": () => import("./../../../src/pages/sara-pfost.js" /* webpackChunkName: "component---src-pages-sara-pfost-js" */)
}

